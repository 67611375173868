import React from "react";
import WarningSharpIcon from "@mui/icons-material/WarningSharp";
import { Box } from "@mui/material";

import { IconProps } from "../types";

import MhcTooltip from "common/components/MhcTooltip";

export const NeedsAttentionIcon: React.FC<IconProps> = ({
  title = "Needs Attention",
  tooltipped = true,
  ...iconProps
}) => {
  const Icon = (
    <Box className="svg-icon-wrapper" data-testid="needs-attention-icon">
      <WarningSharpIcon fontSize="inherit" color="warning" {...iconProps} />
    </Box>
  );
  if (!tooltipped) return Icon;
  return (
    <MhcTooltip title={title} label="Needs Attention Icon" arrow>
      {Icon}
    </MhcTooltip>
  );
};
