"use client";

import { ReactNode } from "react";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { Box, SvgIconProps, TooltipProps, Typography } from "@mui/material";

import MhcTooltip from "../MhcTooltip";

interface SuppressionTooltipProps {
  overrideMessage?: string;
  fontSize?: SvgIconProps["fontSize"] | undefined;
  overrideColor?: string;
  containerSx?: TooltipProps["sx"];
  children?: ReactNode;
}

export const SuppressionTooltip: React.FC<SuppressionTooltipProps> = ({
  overrideMessage,
  fontSize,
  overrideColor,
  containerSx = {}
}) => {
  return (
    <MhcTooltip
      label="Suppressed Value"
      title={
        overrideMessage !== undefined
          ? overrideMessage
          : "This value is not shown in order to protect patient privacy."
      }
      arrow
      sx={{
        color: overrideColor !== undefined ? overrideColor : "light.primary"
      }}
    >
      <Box
        className="svg-icon-wrapper"
        sx={{ display: "block", ...containerSx }}
        data-testid="KpiSuppressedBoxTestId"
      >
        <VisibilityOffOutlined
          fontSize={fontSize !== undefined ? fontSize : "inherit"}
          color="inherit"
        />
      </Box>
    </MhcTooltip>
  );
};

export const SuppressionTooltipNA: React.FC<SuppressionTooltipProps> = ({ overrideMessage }) => {
  return (
    <MhcTooltip
      label="Suppressed Value"
      title={
        overrideMessage !== undefined
          ? overrideMessage
          : "This value is not shown in order to protect patient privacy."
      }
      arrow
      sx={({ palette }) => ({ color: palette.light.primary })}
    >
      <Box component="span">
        <Typography variant="body3" color="textSecondary" component="span">
          N/A
        </Typography>
      </Box>
    </MhcTooltip>
  );
};
