import { SLIDER_FILL_DIRECTION } from "../types/types";

export const sliderFillPercentage = (
  leftValue: number,
  selectedValue: number,
  rightValue: number,
  fillDirection: SLIDER_FILL_DIRECTION
) => {
  const value = Math.abs(
    fillDirection === "left_to_right" ? leftValue - selectedValue : rightValue - selectedValue
  );
  const R = leftValue > rightValue ? leftValue : rightValue;
  return (value * 100) / R;
};

export const sliderEmptyPercentage = (
  leftValue: number,
  selectedValue: number,
  rightValue: number,
  fillDirection: SLIDER_FILL_DIRECTION
) => {
  return 100 - sliderFillPercentage(leftValue, selectedValue, rightValue, fillDirection);
};
