"use client";

import { useState } from "react";
import { Box, Divider, Link, Typography } from "@mui/material";

export const AgeAdjustedVsCrudeRatePopover = () => {
  const [readMore, setReadMore] = useState<boolean>(false);

  return (
    <Box
      padding="20px"
      width={{
        md: "500px",
        xs: "350px"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h5" color="light.primary" sx={{ mr: "auto" }}>
          Age-Adjusted Rate vs. Crude Rate
        </Typography>
        {false && (
          <Link href="#" sx={{ ml: "auto" }}>
            <Typography component="span" variant="body2" color="component.links">
              Go to FAQs
            </Typography>
          </Link>
        )}
      </Box>
      <Divider sx={{ my: "10px" }} />
      <Typography variant="body1" color="light.primary">
        The age-adjusted rate provides a more accurate comparison of rates by accounting for age
        differences, whereas the crude rate provides a simple overall measure without any
        adjustments.
      </Typography>
      {!readMore && (
        <Typography
          onClick={() => setReadMore(true)}
          component="p"
          variant="body2"
          sx={{ background: "none", textDecoration: "underline", mt: "10px", cursor: "pointer" }}
          color="component.links"
        >
          Read more
        </Typography>
      )}
      {readMore && (
        <>
          <br />
          <Typography variant="body1" color="light.primary">
            The crude rate is a basic measure that calculates the frequency or occurrence of an
            event in a population without accounting for any specific factors. It is a
            straightforward calculation dividing the number of events by the total population.
          </Typography>
          <br />
          <Typography variant="body1" color="light.primary">
            In contrast, the age-adjusted rate takes into account the age distribution of the
            population to allow for a fairer comparison between different populations or groups by
            removing the confounding effect of age differences. It is calculated by applying weights
            to age-specific rates and averaging them based on a standard population.
          </Typography>
        </>
      )}
    </Box>
  );
};
