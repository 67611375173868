import { ElementType } from "react";
import { Box } from "@mui/material";

import { iconDictionary, IconId, IconTitles } from "../Icons/utils";

interface KpiIconProps {
  icons: IconId[];
  titles?: IconTitles | null;
}

export const KpiIcons: React.FC<KpiIconProps> = ({ icons, titles }) => (
  <Box component="span" display="flex" alignItems="center" mr={0.5}>
    {icons.map((icon, i) => {
      const Icon = iconDictionary[icon] as ElementType;
      if (!Icon) return null;
      return (
        <Box mr={0.5} key={i} display="flex" alignItems="center" className="svg-icon-wrapper">
          <Icon title={titles ? titles[icon] : undefined} />
        </Box>
      );
    })}
  </Box>
);
