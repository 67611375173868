"use client";

import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  BoxProps,
  Typography
} from "@mui/material";

import { FcWithChildren } from "common/components/types";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

import useAccordionVariant from "./useAccordionVariant";

export const AccordionAllowedVariants = ["white", "blue", "grey"] as const;
export type AccordionVariantsType = (typeof AccordionAllowedVariants)[number];

export type MhcAccordionProps = {
  accordionSx?: BoxProps["sx"];
  contentWrapperSx?: BoxProps["sx"];
  defaultOpen?: boolean;
  expanded?: boolean;
  id?: string;
  label?: string;
  onChange?: AccordionProps["onChange"];
  title: React.ReactNode;
  uiLocation?: string;
  variant: AccordionVariantsType;
};

export const MhcAccordion: FcWithChildren<MhcAccordionProps> = ({
  accordionSx,
  children,
  contentWrapperSx = { p: 4 },
  defaultOpen,
  expanded,
  id,
  label = "",
  onChange,
  title,
  uiLocation,
  variant = "white"
}) => {
  const { borderedStyle, expandIconColor, accordionSummaryBackgroundColor } =
    useAccordionVariant(variant);

  return (
    <Accordion
      expanded={expanded}
      defaultExpanded={defaultOpen ?? false}
      elevation={0}
      sx={{ "&::before": { display: "none" }, ...accordionSx }}
      onChange={(event, expanded) => {
        sendGaUserInteractionEvent({
          category: "Accordions",
          action: expanded ? "Open" : "Close",
          label,
          ui_location: uiLocation
        });
        onChange?.(event, expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: expandIconColor }} />}
        aria-controls={id ? `${id}-content` : undefined}
        id={id ? `${id}-header` : undefined}
        sx={{
          backgroundColor: accordionSummaryBackgroundColor,
          color: expandIconColor,
          ...borderedStyle,
          fontWeight: 700
        }}
      >
        {typeof title === "string" && <Typography>{title}</Typography>}
        {typeof title !== "string" && title}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          margin: 0,
          ...borderedStyle,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}
      >
        <Box sx={contentWrapperSx}>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};
