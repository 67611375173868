import { Box, Grid, SvgIconProps, Typography } from "@mui/material";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { DynamicTrendLineChart } from "../charts/DynamicTrendLineChart";
import { SuppressionTooltip } from "../SuppressionTooltip/SuppressionTooltip";
import KpiValue, { KpiValueSize } from "./KpiValue";
import { EnhancedKpiProps, KpiProps } from ".";

export type KpiValueAndTrendProps = Pick<
  KpiProps,
  "value" | "rawValue" | "statCaption" | "suppressed"
>;

interface Props extends KpiValueAndTrendProps {
  size?: KpiValueSize;
  htmlId?: string;
  enhancement?: EnhancedKpiProps;
  kpiFillsContainer?: boolean;
}

const KpiValueAndTrend: React.FC<Props> = ({
  enhancement,
  htmlId,
  kpiFillsContainer = false,
  size = "medium",
  suppressed,
  value,
  statCaption
}) => {
  const suppressionFontSize: SvgIconProps["fontSize"] = !size.match(/large|medium/)
    ? "small"
    : "inherit";
  const granularity =
    enhancement?.granularity ||
    enhancement?.trendProps?.granularity ||
    MhcTimeSeriesGranularityEnum.Year;

  return (
    <Box
      data-testid={"KpiValueAndTrendTestId"}
      aria-labelledby={htmlId}
      sx={{
        display: "flex",
        gap: 0,
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
      }}
    >
      {!suppressed && (
        <Grid container spacing={2} data-testid="KpiNonSuppressedBoxTestId">
          <Grid
            item
            xs={12}
            lg={enhancement?.trendProps ? 5 : 12}
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
          >
            <KpiValue
              value={value}
              caption={statCaption}
              size={size}
              kpiFillsContainer={kpiFillsContainer}
            />
          </Grid>
          {enhancement?.trendProps && (
            <Grid item xs={12} lg={7} data-testid="KpiTrendTestId">
              <Box sx={{ flex: "1 1 auto" }}>
                <DynamicTrendLineChart
                  {...enhancement?.trendProps}
                  granularity={granularity}
                  width={null}
                  height={75}
                  gAEventProps={{
                    category: "KPI",
                    action: "Sparkline Hover",
                    label: ""
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      {suppressed && (
        <Box display="flex" alignItems="stretch">
          <SuppressionTooltip
            overrideColor="rgba(0, 0, 0, 0.75)"
            fontSize={suppressionFontSize}
            containerSx={{ display: "flex", alignItems: "center" }}
          />
          <Typography variant="h4" sx={{ ml: 0.75, color: "rgba(0, 0, 0, 0.75)" }}>
            No data available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default KpiValueAndTrend;
