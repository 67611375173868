import { Typography } from "@mui/material";

import { EnhancedKpiProps } from "./Kpi";

const KpiDatePeriod: React.FC<{
  datePeriod?: EnhancedKpiProps["dataDatePeriod"];
  htmlId?: string;
}> = ({ datePeriod, htmlId }) => {
  if (!datePeriod) return null;
  return (
    <Typography
      id={htmlId}
      data-testid="kpi-date-period"
      variant="body2"
      color={"light.primary"}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 0.5,
        mb: 0.5,
        whiteSpace: "pre-wrap",
        lineHeight: 1
      }}
    >
      {datePeriod}
    </Typography>
  );
};

export default KpiDatePeriod;
