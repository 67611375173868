import CheckIcon from "@mui/icons-material/CheckRounded";

import { IconProps } from "../types";

import { IconWrapper } from "../IconWrapper";

export const TargetMetIcon: React.FC<IconProps> = ({
  title = "Target met or exceeded",
  ...iconProps
}) => {
  return (
    <IconWrapper title={title}>
      <CheckIcon fontSize="inherit" color="success" {...iconProps} />
    </IconWrapper>
  );
};
