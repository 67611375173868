"use client";

import { cloneElement, useEffect } from "react";
import { Popover, PopoverProps } from "@mui/material";
import { bindPopover, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

interface Props {
  id?: string;
  label?: string;
  children: React.ReactElement;
  content: React.ReactNode;
  sx?: PopoverProps["sx"];
  uiLocation?: string;
}

export const PopoverContainer: React.FC<Props> = ({
  id,
  label,
  children,
  content,
  sx,
  uiLocation
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: id
  });

  useEffect(() => {
    if (popupState.isOpen) {
      popupState.anchorEl?.setAttribute("aria-pressed", "true");
      sendGaUserInteractionEvent({
        category: "Popovers",
        action: "Open",
        label: (label || id) ?? "",
        ui_location: uiLocation ?? window.location.pathname
      });
    } else {
      popupState.anchorEl?.setAttribute("aria-pressed", "false");
      (popupState.anchorEl as HTMLElement)?.blur();
    }
  }, [popupState.isOpen, popupState.anchorEl, label, id, uiLocation]);

  return (
    <>
      {cloneElement(children, bindTrigger(popupState))}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        sx={sx}
      >
        {content}
      </Popover>
    </>
  );
};
