import { MhcTimeSeries } from "graphqlApi/types";

import { getLatestDateFromArray } from "common/util/getLatestDate";
import { getUtcDateFromString } from "common/util/utcDateFromString";

interface Props {
  timeSeries: MhcTimeSeries;
}

export const getStartAndEndDatesFromTimeSeries = ({ timeSeries }: Props) => {
  const first = timeSeries.dates ? getLatestDateFromArray(timeSeries.dates, false) : null;
  const last = timeSeries.dates ? getLatestDateFromArray(timeSeries.dates) : null;
  const startDate = getUtcDateFromString(first) as Date;
  const endDate = getUtcDateFromString(last) as Date;

  return {
    startDate,
    endDate
  };
};
