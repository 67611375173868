import { Box } from "@mui/material";

import { SliderSectionProps } from "./types/types";

const SliderSection: React.FC<SliderSectionProps> = ({ width, height, color, index }) => (
  <Box
    width={width ? `${width}%` : undefined}
    key={index}
    sx={{
      flex: width ? undefined : 1,
      height: `${height}px`,
      backgroundColor: color,
      borderLeft: index !== 0 ? `2px dashed white` : undefined
    }}
  />
);

export default SliderSection;
