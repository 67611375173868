import { Box, Typography } from "@mui/material";

import { PopoverContainer } from "../PopoverContainer";
import { AgeAdjustedVsCrudeRatePopover } from "./AgeAdjustedVsCrudeRatePopover";

const KpiAgeAdjustedPopover = () => (
  <PopoverContainer
    id="ageAdjustedVsRate"
    content={<AgeAdjustedVsCrudeRatePopover />}
    sx={{ mt: "10px" }}
  >
    <Box
      aria-label="What is the difference between an age-adjusted rate and a crude rate? Tooltip"
      role="button"
      aria-pressed="false"
      tabIndex={0}
      component="button"
      sx={{
        background: "transparent",
        border: "transparent",
        p: 0,
        m: 0,
        height: "min-content"
      }}
    >
      <Typography
        component="p"
        variant="caption"
        color="component.links"
        sx={{
          cursor: "pointer",
          my: "auto",
          textAlign: { xs: "left", md: "right" },
          textDecoration: "underline"
        }}
      >
        What is the difference between an age-adjusted rate and a crude rate?
      </Typography>
    </Box>
  </PopoverContainer>
);

export default KpiAgeAdjustedPopover;
