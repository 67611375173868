import { SectionedSliderProps, SliderKeys } from "../types/types";

import { sliderSectionColors } from "theme/colors";

type GetSliderColors = {
  colorSet?: SliderKeys;
  sectionColors: SectionedSliderProps["sectionColors"];
};

export const getSliderColors = ({
  colorSet,
  sectionColors
}: GetSliderColors): SectionedSliderProps["sectionColors"] => {
  const colors: SectionedSliderProps["sectionColors"] | undefined = colorSet
    ? sliderSectionColors[colorSet]
    : sectionColors;
  return colors ?? (sliderSectionColors?.default as SectionedSliderProps["sectionColors"]);
};

export const calculateSliderIndicatorPosition = ({
  value,
  min,
  max,
  asPercentage = true
}: {
  value: number;
  min: number;
  max: number;
  asPercentage?: boolean;
}): string | number => {
  let result = 0;
  if (value <= min) {
    result = 0;
  } else if (value >= max) {
    result = 100;
  } else {
    result = ((value - min) / (max - min)) * 100;
  }
  if (asPercentage) {
    return `${result}%`;
  }
  return result;
};
