import { ReactNode } from "react";
import { BoxProps, Stack } from "@mui/material";
import isNil from "lodash/isNil";

interface KpiWrapperProps {
  mySx?: BoxProps["sx"];
  index?: number;
  identifier?: string;
  children?: ReactNode;
}

const KpiWrapper: React.FC<KpiWrapperProps> = ({ mySx = {}, index, children, identifier }) => {
  return (
    <Stack
      sx={mySx}
      gap={2}
      className="kpi"
      data-testid={`kpi-container${!isNil(index) ? `-${index}` : ""}`}
      data-identifier={identifier}
    >
      {children}
    </Stack>
  );
};

export default KpiWrapper;
