import React from "react";
import { Stack, StackProps } from "@mui/material";

const Row: React.FC<StackProps> = ({ children, ...props }) => (
  <Stack direction="row" {...props}>
    {children}
  </Stack>
);

export default Row;
