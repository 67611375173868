import { ElementType } from "react";

import { MhcStatIdentifierFragment, MhcStatImprovementEnum } from "graphqlApi/types";

import { formatValueByUnit } from "common/util/formatHelpers";
import {
  NeedsAttentionProps,
  statIdentifierTarget
} from "common/util/formatHelpers/statIdentifierHelpers";
import { titleize } from "common/util/helpers";
import { lowerHigherWithParticle } from "common/util/lowerHigherWithParticle";

import { ImprovingIcon } from "./Improving";
import { NeedsAttentionIcon } from "./NeedsAttention";
import { TargetMetIcon } from "./TargetMet";

export const IconIdEnum = {
  NeedsAttention: "needsAttention",
  Improving: "improving",
  TargetMet: "targetMet"
} as const;
type IconIdEnum = (typeof IconIdEnum)[keyof typeof IconIdEnum];
export const ICON_IDS = [IconIdEnum.NeedsAttention, IconIdEnum.Improving, IconIdEnum.TargetMet];
export type IconId = (typeof ICON_IDS)[number];
export type IconTitles = Record<IconId | string, string>;

export const showImprovingIcon = ({
  change,
  improvementType
}: {
  change?: number;
  improvementType?: MhcStatImprovementEnum | null;
}) => {
  if (!change) return false;
  const higherIsBetter = change > 0 && improvementType === MhcStatImprovementEnum.HigherIsBetter;
  const lowerIsBetter = change < 0 && improvementType === MhcStatImprovementEnum.LowerIsBetter;
  return higherIsBetter || lowerIsBetter;
};

export const iconDictionary: Record<IconId, ElementType> = {
  needsAttention: NeedsAttentionIcon,
  improving: ImprovingIcon,
  targetMet: TargetMetIcon
};

export const statTargetValue = (statId: MhcStatIdentifierFragment) => {
  statIdentifierTarget(statId)?.value ?? null;
};

export const showNeedsAttentionIcon = ({
  currentValue,
  target,
  improvementType
}: NeedsAttentionProps) => {
  if (!currentValue || !target) return false;
  const higherIsBetter =
    currentValue < currentValue && improvementType === MhcStatImprovementEnum.HigherIsBetter;
  const lowerIsBetter =
    currentValue > target && improvementType === MhcStatImprovementEnum.LowerIsBetter;
  return higherIsBetter || lowerIsBetter;
};

interface NeedsAttentionTextProps {
  si?: MhcStatIdentifierFragment;
  lastValue?: number | null;
  targetValue?: number | null;
}

export const needsAttentionDescription = ({
  si,
  lastValue,
  targetValue
}: NeedsAttentionTextProps) => {
  if (!si || !lastValue || !targetValue) return "";
  const { unit, precision, isPercent } = si;
  const unitWords = unit ? `${titleize(unit)} is ` : "";
  const presentedTargetValue = formatValueByUnit({
    value: targetValue,
    unit,
    precision,
    isPercent: isPercent ?? false
  });
  return `
    Needs Attention:
    ${unitWords}
    ${lowerHigherWithParticle(
      lastValue,
      targetValue,
      "than",
      "to"
    )} the target value of ${presentedTargetValue}.
  `;
};
