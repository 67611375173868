import compact from "lodash/compact";
import isNil from "lodash/isNil";
import uniqBy from "lodash/uniqBy";

import {
  MhcAttribution,
  MhcAttributionFragment,
  MhcStatIdentifier,
  MhcStatIdentifierFragment,
  MhcStatImprovementEnum,
  MhcTarget,
  MhcTargetFragment
} from "graphqlApi/types";

import { LoadedStat } from "modules/Topics/util/fetchingFunctions/fetchStatsForAllSections";

import { ValueOptions } from "common/components/charts/Trendline";

export const targetForStatIdentifier = (
  identifier: MhcStatIdentifierFragment
): MhcTarget | null => {
  if (identifier.targets !== undefined && (identifier?.targets?.length ?? 0) > 0) {
    if (identifier.targets === null || identifier.targets === undefined) {
      return null;
    }
    return identifier.targets[(identifier?.targets?.length ?? 0) - 1] ?? null;
  }
  return null;
};

export interface NeedsAttentionProps {
  currentValue?: number | null;
  targetValue?: number | null;
  target?: number | null;
  improvementType?: MhcStatImprovementEnum | null;
}

export const needsAttention = ({
  currentValue,
  targetValue,
  improvementType
}: NeedsAttentionProps) => {
  let determination = false;
  if (isNil(currentValue) || isNil(targetValue)) return determination;
  if (improvementType === MhcStatImprovementEnum.HigherIsBetter) {
    determination = currentValue < targetValue;
  }
  if (improvementType === MhcStatImprovementEnum.LowerIsBetter) {
    determination = currentValue > targetValue;
  }
  return determination;
};

export const isImproving = ({
  change,
  improvementType
}: {
  change?: number;
  improvementType?: MhcStatImprovementEnum | null;
}) => {
  if (!change) return false;
  const higherIsBetter = change > 0 && improvementType === MhcStatImprovementEnum.HigherIsBetter;
  const lowerIsBetter = change < 0 && improvementType === MhcStatImprovementEnum.LowerIsBetter;
  return higherIsBetter || lowerIsBetter;
};

export const getValueOptionsFromSi = ({
  unit = null,
  precision = 0,
  isPercent = false
}: Partial<MhcStatIdentifier | MhcStatIdentifierFragment>): ValueOptions => ({
  unit,
  precision,
  isPercent
});

export const getAttributionsOfStatIdentifiers = (
  statIdentifiers: Array<MhcStatIdentifier | MhcStatIdentifierFragment>
) => statIdentifiers.flatMap((si) => (si?.attributions as MhcAttributionFragment[]) ?? []);

export const getAttributionsByStat = (fromStats: LoadedStat[] = []) => {
  const unique = uniqBy(
    fromStats.flatMap(
      ({ statIdentifier: { attributions, id } }) =>
        attributions?.map((attribution) => ({ ...attribution, statId: id })) ?? []
    ),
    "id"
  );
  return unique.reduce((dict, attr) => {
    const current = dict[attr.statId];
    if (isNil(current)) {
      return {
        ...dict,
        [attr.statId]: [attr]
      };
    }
    return {
      ...dict,
      [attr.statId]: [...current, attr]
    };
  }, {} as Record<string, MhcAttributionFragment[]>) as Record<string, MhcAttributionFragment[]>;
};

export const getAttributionsFromStatIdentifiers = (
  statIdentifiers: (MhcStatIdentifier | MhcStatIdentifierFragment)[]
): (MhcAttribution | MhcAttributionFragment)[] =>
  uniqBy(
    compact(
      statIdentifiers.flatMap(
        ({ attributions }) => (attributions as MhcAttributionFragment[]) ?? []
      )
    ),
    "id"
  );

export const getAttributionsFromStats = (stats: LoadedStat[]): MhcAttributionFragment[] =>
  uniqBy(compact(stats.flatMap(({ statIdentifier }) => statIdentifier?.attributions ?? [])), "id");

export const statIdentifierTarget = (
  statIdentifier: MhcStatIdentifierFragment
): MhcTargetFragment | undefined | null => {
  if (statIdentifier.targets?.length === 0) {
    return null;
  }
  return statIdentifier.targets?.[(statIdentifier?.targets?.length ?? 0) - 1];
};
