import { ReactNode } from "react";

import { SimpleSectionedSliderProps, SliderKeys } from "../Ranges/types/types";

import { sliderSectionColors } from "theme/colors";

import { SimpleSectionedSlider } from "../Ranges/SimpleSectionedSlider";
import { KpiProps } from "./Kpi";
import { KpiValueAndTrendProps } from "./KpiValueAndTrend";

export type AdditionalVisualizationProps<
  AdditionalOptions extends object = Omit<SimpleSectionedSliderProps, "type">
> = {
  type?: KPIVisualization;
  colorSet?: SliderKeys | SimpleSectionedSliderProps["colorSet"];
  value?: KpiProps["value"];
  rawValue?: KpiProps["rawValue"];
  options?: AdditionalOptions;
};

export const KPIVisualizationEnum = {
  SparkLine: "spark-line",
  Slider: "slider"
} as const;
export type KPIVisualization = (typeof KPIVisualizationEnum)[keyof typeof KPIVisualizationEnum];
export const KPIVisualizations = [KPIVisualizationEnum.SparkLine, KPIVisualizationEnum.Slider];

interface Props extends Omit<KpiValueAndTrendProps, "value" | "type"> {
  indicatorName?: string;
  type?: KPIVisualization | null;
  labels?: SimpleSectionedSliderProps["labels"];
  colorSet?: SliderKeys | SimpleSectionedSliderProps["sectionColors"];
  value?: ReactNode | number | null;
  options?: AdditionalVisualizationProps;
}

const KpiAdditionalVisualization: React.FC<Props> = ({
  indicatorName = "",
  rawValue,
  value,
  statCaption,
  type,
  colorSet = "default",
  labels = [],
  options
}) => {
  if (!type) return null;
  const additionalVisualization = (_type: KPIVisualization) => {
    const _value = rawValue ?? (value as number | string);
    if (!["string", "number"].includes(typeof _value)) {
      return null;
    }
    if (_type === KPIVisualizationEnum.Slider) {
      let title = value ? `${value as number | string} ` : "";
      if (statCaption !== undefined) {
        title += `(${statCaption as string | number})`;
      }
      const sectionColors = typeof colorSet === "string" ? sliderSectionColors[colorSet] : colorSet;
      const props = {
        barHeight: 12,
        fillDirection: "left_to_right",
        indicatorName: indicatorName,
        labels: labels,
        leftValue: 0,
        rightValue: 1,
        sectionColors: sectionColors ?? [],
        selectedValue: parseFloat(_value as string),
        tooltipTitle: title.trim(),
        ...options
      } as SimpleSectionedSliderProps;
      return <SimpleSectionedSlider {...props} />;
    } else {
      return null;
    }
  };

  return additionalVisualization(type);
};

export default KpiAdditionalVisualization;
