import { Typography } from "@mui/material";

import { KpiProps } from "./Kpi";

export const KPI_ADDITIONAL_INFO_LINE_HEIGHT = "20px";

const KpiAdditionalInformation: React.FC<{ information?: KpiProps["additionalInformation"] }> = ({
  information
}) => {
  if (!information) return null;
  return (
    <Typography
      variant="body2"
      component={typeof information === "string" ? "p" : "div"}
      sx={{
        flexGrow: 0,
        flexShrink: 1,
        lineHeight: KPI_ADDITIONAL_INFO_LINE_HEIGHT
      }}
    >
      {information}
    </Typography>
  );
};

export default KpiAdditionalInformation;
