import { ReactElement } from "react";
import { Box, Typography, TypographyProps } from "@mui/material";
import isNil from "lodash/isNil";
import kebabCase from "lodash/kebabCase";
import uniqueId from "lodash/uniqueId";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { InfoIcon } from "common/components/InfoIcon";
import KpiAgeAdjustedPopover from "common/components/KPI/KpiAgeAdjustedPopover";
import { KpiProps } from ".";

export interface KpiTitleProps extends Pick<KpiProps, "id" | "info" | "title" | "ageAdjusted"> {
  addMargin?: boolean;
  htmlId?: string;
  isSuppressed?: boolean;
  sx?: TypographyProps["sx"];
  additionalContentElement?: ReactElement;
  isPercent?: boolean;
}

type ShowAgeAdjusted = {
  isPercent?: boolean;
  ageAdjusted?: boolean | null;
  id: string;
};
export const isAgeAdjusted = ({ isPercent = false, ageAdjusted, id }: ShowAgeAdjusted) => {
  if (isPercent === true) return false;
  if (!isNil(ageAdjusted)) return ageAdjusted;
  if (!isNil(id)) {
    return id.match(/^((?!CRUDE|PERCENTAGE).)*RATE/);
  }
  return false;
};

const KpiTitle: React.FC<KpiTitleProps> = ({
  id,
  addMargin,
  htmlId,
  info,
  isSuppressed = false,
  title = null,
  sx,
  additionalContentElement = null,
  ageAdjusted,
  isPercent = false
}) => {
  const isNotMobile = !useIsMobile();

  const showAgeAdjusted = isAgeAdjusted({ isPercent, ageAdjusted, id });

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography
        component="span"
        id={htmlId}
        sx={{
          display: "flex",
          gap: "3px",
          flexWrap: "wrap",
          mb: addMargin ? 0.5 : 0,
          ...sx
        }}
      >
        <Typography
          data-testid={`kpi-title-${
            id ?? typeof title === "string" ? kebabCase(title as string) : htmlId ?? uniqueId()
          }`}
          variant="body1"
          fontWeight={600}
        >
          {title}
        </Typography>
        {info && isSuppressed !== true && (
          <InfoIcon data-testid="kpi-info-icon" variant="dark" sx={{ display: "inline" }}>
            {info}
          </InfoIcon>
        )}
      </Typography>
      {isNotMobile && showAgeAdjusted && <KpiAgeAdjustedPopover />}
      {additionalContentElement}
    </Box>
  );
};

export default KpiTitle;
