import { Stack, Typography } from "@mui/material";

import { SliderLabelsProps } from "./types/types";

const SliderLabels: React.FC<SliderLabelsProps> = ({
  labels,
  textVariant = "body2",
  fontWeight = 600,
  sx = {}
}) => {
  const _labels = labels?.filter((l) => l);
  if (!_labels || _labels.length === 0) return null;
  return (
    <Stack direction="row" justifyContent="space-between" sx={sx}>
      {_labels.map((l) => (
        <Typography variant={textVariant} key={l} fontWeight={fontWeight} component="span">
          {l}
        </Typography>
      ))}
    </Stack>
  );
};

export default SliderLabels;
