import isNil from "lodash/isNil";

import { logError } from "common/util/consoleHelpers";

/**
 * Calculates percent change of two numbers
 *
 * @param params
 * @param params.lastValue
 * @param params.firstValue
 *
 * @returns unformatted percent change
 */
const calculatePercentChange = ({
  firstValue,
  lastValue
}: {
  firstValue: number | null;
  lastValue: number | null;
}) => {
  if (firstValue === 0) return null;
  if (isNil(lastValue) || isNil(firstValue)) {
    logError("Percent change calculator given undefined or null values");
    return 0;
  }
  return ((lastValue - firstValue) / (firstValue === 0 ? 1 : firstValue)) * 100;
};

export default calculatePercentChange;
