"use client";

import { Link, Typography, TypographyProps } from "@mui/material";

interface LinkOrTextProps {
  renderAsLink: boolean;
  text: string;
  callback?: (text: string) => void;
  href: string;
  textComponent?: "p" | "span";
  textVariant?: TypographyProps["variant"];
  textSx?: TypographyProps["sx"];
  target?: "_blank" | "_self" | "_parent" | "_top";
}

export const LinkOrText: React.FC<LinkOrTextProps> = ({
  renderAsLink,
  text,
  callback,
  href,
  textComponent = "p",
  textVariant = "body1",
  textSx = {},
  target = "_blank"
}) => {
  if (renderAsLink) {
    return (
      <Link onClick={() => callback && callback(text)} href={href} target={target}>
        <Typography sx={textSx} component="span" variant={textVariant}>
          {text}
        </Typography>
      </Link>
    );
  } else {
    return (
      <Typography sx={textSx} component={textComponent} variant={textVariant}>
        {text}
      </Typography>
    );
  }
};
