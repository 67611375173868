import React from "react";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow";

import { IconProps } from "../types";

import MhcTooltip from "common/components/MhcTooltip";

export const ImprovingIcon: React.FC<IconProps> = ({
  title = "Improving",
  tooltipped = true,
  ...iconProps
}) => {
  const Icon = (
    <BrightnessLowIcon
      fontSize="inherit"
      sx={{ color: "common.black" }}
      {...iconProps}
      data-testid="improving-icon"
    />
  );
  if (!tooltipped) return Icon;
  return (
    <MhcTooltip title={title} label="Improving Icon" arrow>
      {Icon}
    </MhcTooltip>
  );
};
