import { Box, Stack } from "@mui/material";

import { SimpleSectionedSliderProps } from "./types/types";

import { sliderEmptyPercentage, sliderFillPercentage } from "./util/percentageCalculator";

import MhcTooltip from "../MhcTooltip";
import SliderLabels from "./SliderLabels";
import SliderSections from "./SliderSections";

export const SimpleSectionedSlider: React.FC<SimpleSectionedSliderProps> = ({
  barHeight = 20,
  colorSet,
  fillDirection = "left_to_right",
  indicatorName = "",
  labels,
  leftValue = 0,
  rightValue = 1,
  sectionColors = [],
  sectionLabels,
  sectionWidths,
  selectedValue,
  tooltipTitle
}) => {
  const fillPercentage = selectedValue
    ? sliderFillPercentage(leftValue, selectedValue, rightValue, fillDirection)
    : "";
  const emptyPercentage = selectedValue
    ? sliderEmptyPercentage(leftValue, selectedValue, rightValue, fillDirection)
    : "";
  const indicatorWidth = 7;
  const indicatorHeight = barHeight + 20;
  const renderLeftToRight = fillDirection === "left_to_right";
  const indicator = (
    <Box
      sx={{
        width: indicatorWidth,
        borderRadius: 1,
        height: indicatorHeight,
        border: "1px solid white",
        background: "#000",
        left: renderLeftToRight ? `${fillPercentage}%` : null,
        right: !renderLeftToRight ? `${emptyPercentage}%` : null,
        position: "absolute",
        transform: `translate(${renderLeftToRight ? "-" : ""}${indicatorWidth / 2}px, -${
          (indicatorHeight - barHeight) / 2
        }px)`,
        zIndex: 10
      }}
    />
  );
  return (
    <Box sx={{ position: "relative", my: 2 }}>
      {tooltipTitle ? (
        <MhcTooltip title={tooltipTitle} label={`Sectioned Slider - ${indicatorName}`} arrow>
          {indicator}
        </MhcTooltip>
      ) : (
        indicator
      )}

      <Stack>
        <SliderSections
          colorSet={colorSet}
          sectionColors={sectionColors}
          barHeight={barHeight}
          sectionWidths={sectionWidths}
          sectionLabels={sectionLabels}
        />
        <SliderLabels labels={labels} fontWeight={400} />
      </Stack>
    </Box>
  );
};
