import { Stack } from "@mui/material";

import { SliderSectionsProps } from "./types/types";

import { getSliderColors } from "./util/slider";

import MhcTooltip from "../MhcTooltip";
import SliderSection from "./SliderSection";

const SliderSections: React.FC<SliderSectionsProps> = ({
  colorSet,
  sectionColors,
  sectionWidths,
  sectionLabels,
  barHeight = 30
}) => {
  const colors = getSliderColors({
    colorSet,
    sectionColors
  });
  const borderRadius = Math.round(barHeight / 2.5);
  return (
    <Stack
      direction="row"
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.25)",
        borderRadius,
        overflow: "hidden",
        justifyContent: "space-between"
      }}
    >
      {colors.map((color, index) => {
        const section = (
          <SliderSection
            color={color}
            height={barHeight}
            width={sectionWidths?.[index]}
            key={`section-${index}`}
            index={index}
          />
        );
        if (sectionLabels?.[index]) {
          return (
            <MhcTooltip key={index} label="Slider section" title={sectionLabels?.[index] as string}>
              {section}
            </MhcTooltip>
          );
        } else {
          return section;
        }
      })}
    </Stack>
  );
};

export default SliderSections;
