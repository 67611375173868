import { ReactNode } from "react";
import { Box } from "@mui/material";

import MhcTooltip from "../MhcTooltip";

interface Props {
  title?: string;
  children?: ReactNode;
}

export const IconWrapper: React.FC<Props> = ({ title = "", children }) => {
  return (
    <MhcTooltip title={title} label={title} arrow>
      <Box className="svg-icon-wrapper">{children}</Box>
    </MhcTooltip>
  );
};
