import { Typography } from "@mui/material";

const KpiGeography: React.FC<{ locationName?: string | null; htmlId?: string }> = ({
  locationName,
  htmlId
}) => {
  if (!locationName) return null;
  return (
    <Typography
      data-testid="kpi-geography"
      id={htmlId}
      variant="body3"
      color="brandSecondary.main"
      fontWeight={400}
    >
      {locationName}
    </Typography>
  );
};

export default KpiGeography;
