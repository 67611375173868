import { useMemo } from "react";
import dynamic from "next/dynamic";

import { TrendlineProps } from "common/components/charts/Trendline";

// dynamic component to get so highcharts resizes correctly
export const DynamicTrendLineChart: React.FC<TrendlineProps> = (props) => {
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/charts/Trendline"), {
        loading: () => <div>Loading...</div>,
        ssr: false
      }),
    [
      /* list variables which should trigger a re-render here */
    ]
  );
  return <Component {...props} />;
};
