import compact from "lodash/compact";
import isNil from "lodash/isNil";

import { DateFromApi } from "graphqlApi/customTypes";

interface GetLatestDateProps {
  knownLatestDate?: DateFromApi;
  newPossibleLatestDate?: DateFromApi;
}

export const getLatestDate = ({ knownLatestDate, newPossibleLatestDate }: GetLatestDateProps) => {
  if (isNil(knownLatestDate) || isNil(newPossibleLatestDate)) {
    return null;
  }
  if (knownLatestDate < newPossibleLatestDate) {
    return newPossibleLatestDate;
  }
  return knownLatestDate;
};

export const getLatestDateFromArray = (
  dates: (DateFromApi | undefined)[],
  latest = true
): DateFromApi => {
  const _dates = compact(dates);
  if (_dates.length === 0) {
    return null;
  }
  const nonNilDates: string[] = _dates.filter((date) => !isNil(date)) as string[];
  const sorted = nonNilDates.sort((a, b) => {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  });
  if (sorted.length === 0) {
    return null;
  }
  return (latest ? sorted[0] : sorted[sorted.length - 1]) ?? null;
};
