import { ReactNode } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import compact from "lodash/compact";

import { MhcTarget, MhcTargetFragment } from "graphqlApi/types";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { LinkOrText } from "common/components/LinkOrText";

export interface TargetTableProps {
  target: MhcTarget | MhcTargetFragment;
  formattedValue?: string;
  subtitle: string;
  progress?: string;
}

type TargetTableRow = [string, string | ReactNode];

export const TargetTable: React.FC<TargetTableProps> = ({
  target,
  formattedValue,
  subtitle,
  progress
}) => {
  const isMobile = useIsMobile();
  const { dataSource, referenceUrl, value, url } = target;
  const rows: TargetTableRow[] = compact([
    [
      "Target Value",
      <Typography key="target-value" display="flex" gap="3px" alignItems="baseline">
        <Typography component="span">{formattedValue || value}</Typography>
        <Typography component="span" variant="body3">
          {subtitle}
        </Typography>
      </Typography>
    ],
    ["Description", <Typography key="description">{target.description}</Typography>],
    progress && ["Progress", <Typography key="progress">{progress}</Typography>],
    dataSource && [
      "Data Source",
      <LinkOrText
        key="data-source"
        renderAsLink={!!referenceUrl}
        href={referenceUrl ?? ""}
        text={dataSource ?? ""}
      />
    ]
  ]);
  return (
    <Table>
      <TableBody>
        {rows.map(([name, cellContent], i) => {
          const isFirstRow = i === 0;
          const isLastRow = i === rows.length - 1;
          const cellStyle = {
            borderWidth: isFirstRow ? 1 : 0,
            fontSize: 14,
            px: 0,
            py: 0.25,
            pt: i === 1 ? 1.5 : undefined,
            pb: isFirstRow || isLastRow ? 1.5 : undefined
          };
          return (
            <TableRow key={name.toLowerCase().replace(" ", "-")}>
              <TableCell
                sx={{
                  ...cellStyle,
                  width: { md: "25%", lg: "10%" }
                }}
              >
                <Typography
                  sx={{
                    fontSize: isFirstRow ? 16 : 14,
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    pr: 4
                  }}
                >
                  {name}
                </Typography>
              </TableCell>
              <TableCell sx={{ ...cellStyle }}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  sx={{ pl: 0.5 }}
                >
                  {cellContent}
                  {!isMobile && isFirstRow && url && (
                    <Link
                      variant="body2"
                      key="url"
                      href={url}
                      target="_blank"
                      sx={{ display: "flex", gap: 0.5, alignItems: "center", lineHeight: 1 }}
                    >
                      <OpenInNewIcon fontSize="inherit" />
                      View more information
                    </Link>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
        {isMobile && (
          <TableRow>
            <TableCell
              colSpan={2}
              sx={{
                borderWidth: 0,
                borderTop: "1px solid",
                borderTopColor: "borders.main",
                fontSize: 14,
                px: 0,
                pt: 2,
                pb: 1
              }}
            >
              <Link
                variant="body2"
                key="url"
                href={url as string}
                target="_blank"
                sx={{ display: "flex", gap: 0.5, alignItems: "center", lineHeight: "1" }}
              >
                <OpenInNewIcon fontSize="inherit" />
                View more information
              </Link>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
