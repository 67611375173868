"use client";

import { useEffect } from "react";
import { Popover } from "@mui/material";
import { bindPopover, PopupState } from "material-ui-popup-state/hooks";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

import { TargetTable, TargetTableProps } from "./TargetTable";
import { POPOVER_ORIGIN } from ".";

const POPOVER_TRANSITION = 200;

export interface Props {
  popupState: PopupState;
  tableProps: TargetTableProps;
  uiLocation?: string;
}

export const TargetPopover: React.FC<Props> = ({ popupState, tableProps, uiLocation = "stat" }) => {
  useEffect(() => {
    if (!popupState.isOpen) return;
    sendGaUserInteractionEvent({
      category: "Popovers",
      action: "Open",
      label: "Target Popover",
      ui_location: [
        `${tableProps.target.title} (${tableProps.target.identifier ?? ""})`,
        uiLocation
      ].join(" - ")
    });
  }, [popupState, uiLocation, tableProps.target]);
  return (
    <Popover
      transitionDuration={POPOVER_TRANSITION}
      anchorOrigin={POPOVER_ORIGIN}
      PaperProps={{
        sx: { width: popupState.anchorEl?.clientWidth ?? "auto", p: 2 }
      }}
      {...bindPopover({ ...popupState })}
    >
      <TargetTable {...tableProps} />
    </Popover>
  );
};
